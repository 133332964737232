import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='bg-primary'>
          <h1>anybeautyuk</h1>
          <p>Bem-vindo ao nosso website!</p>
        </div>
      </header>
    </div>
  );
}

export default App;
